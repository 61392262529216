import { useWeb3React } from "@web3-react/core";
import { useCallback, useState } from "react";
import Web3 from "web3";
import { DAC_ABI } from "../ABI/DAC";
import { MUTANTS_ABI } from "../ABI/MUTANTS";
import { DAC_CONTRACT_ADDRESS, MUTANTS_CONTRACT_ADDRESS } from "../config";

const useMyDAC = (isMutant) => {
  const { account, library } = useWeb3React();
  const [DACPurchased, setDACPurchased] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [tokenIds, setTokenIds] = useState([]);

  const getNFTCollection = useCallback(async () => {
    setIsLoading(true);
    const ownedDAC = [];
    if (account) {
      let tokensOfOwner = [];
      const web3 = new Web3(library.provider);
      let contract;

      if (!isMutant) {
        contract = new web3.eth.Contract(DAC_ABI, DAC_CONTRACT_ADDRESS);
      } else {
        contract = new web3.eth.Contract(MUTANTS_ABI, MUTANTS_CONTRACT_ADDRESS);
      }

      try {
        tokensOfOwner = await contract.methods.tokensOfOwner(account).call();
      } catch (e) {
        setIsLoading(false);
        setError(`Please connect to the correct wallet.`);
      }

      if (typeof tokensOfOwner !== Number && !tokensOfOwner) {
        setError(
          "Something went wrong. Please try again, and ensure you are connected to the right network."
        );
      }

      for (let tokenId of tokensOfOwner) {
        await fetch(
          `https://${isMutant ? "m" : ""}api.degenape.club/api/${tokenId}`
        )
          .then((response) => {
            if (response.ok) return response.json();
            throw new Error("Network response was not ok.");
          })
          .then((data) => {
            const DAC = {
              tokenId: data.id,
              name: data.name,
              image: data.image,
            };
            data.attributes.forEach((attribute) => {
              DAC[attribute.trait_type] = attribute.value;
            });
            ownedDAC.push(DAC);
          })
          .catch((error) => {
            console.error(error);
          });
      }

      setIsLoading(false);
      ownedDAC.sort((a, b) => a.tokenId - b.tokenId);
      const tokenIds = ownedDAC.map((dac) => +dac.tokenId);
      setTokenIds(tokenIds);
      setDACPurchased(ownedDAC);
    }
  }, [account, library, isMutant]);

  return {
    getNFTCollection,
    DACPurchased,
    isLoading,
    error,
    tokenIds,
  };
};

export default useMyDAC;
