import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import Web3 from "web3";
import { DAC_ABI } from "../../ABI/DAC";
import { useWeb3React } from "@web3-react/core";
import logo from "../../images/logo.png";
import previewone from "../../images/PreviewOne.gif";
import previewtwo from "../../images/PreviewTwo.gif";
import previewthree from "../../images/PreviewThree.gif";
import previewfour from "../../images/PreviewFour.gif";

const Wrapper = styled.div`
  width: 100vw;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  box-sizing: border-box;
  .link-dark {
    color: black;
  }
  .test-footer {
    background: var(--mint);
    color: #000;
    text-align: center;
    padding: 20px 0 20px;
    margin: 0 auto;
    margin-top: 50px;
  }
  .hero-title {
    background: -webkit-linear-gradient(
      to right,
      #b4f8c8,
      #99f2c8
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #b4f8c8,
      #99f2c8
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: #b4f8c8;
  }
  .mint-title {
    text-align: center;
    font-size: 32px;
  }
  .mint-p {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
  }
  .mint-countdown {
    color: black;
    font-size: 36px;
  }
  .s_button {
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 20px;
    font-size: 20px;
    font-family: "Retro Gaming", sans-serif;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #000;
    border: none;
    margin: 5px;
  }
  .s_button:hover {
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 20px;
    font-size: 20px;
    font-family: "Retro Gaming", sans-serif;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: black;
    background-color: var(--mint);
    box-shadow: 1px 1px 1px 1px black;
  }
  .ponzi {
    font-size: 1.45em;
    font-weight: 500;
    font-style: italic;
  }
  .hero-div img {
    max-width: 300px;
  }
  .headape {
    border-radius: 35px;
  }
  .title {
    font-size: 30px;
    font-weight: 700;
  }
  .subtitle {
    font-size: 30px;
    text-transform: none;
  }
  .hero-footer {
    background: var(--mint);
    color: #000;
    text-align: center;
    padding: 10px 0 15px;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .hero-footer p {
    font-size: 18px;
  }
  .hero-footer h4 {
    font-weight: 700;
  }
  .borders {
    border: 5px solid #000;
    padding: 4px 15px;
  }
  .borders-left {
    border: 5px solid #000;
    border-right: 0;
    cursor: pointer;
    padding: 4px 15px;
  }
  .borders-right {
    border: 5px solid #000;
    border-left: 0;
    cursor: pointer;
    padding: 4px 15px;
  }
  .icon img {
    width: 100px;
  }
  .button,
  .wallet_button,
  #dropdown {
    background: #000;
    border: 0;
    outline: 0;
    color: #fff;
    padding: 10px 20px 10px;
    font-family: "Retro Gaming", sans-serif;
    font-weight: 700;
    font-size: 24px;
  }

  .hero-footer.claim {
    max-width: 1000px;
    margin-top: 40px;
    padding: 20px 15px 40px;
  }
  #dropdown {
    margin-right: 20px;
    font-family: "Retro Gaming", sans-serif;
  }
  .wallet_button {
    margin: 0 20px;
    // width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
  }
  .italic {
    font-style: italic;
  }
  .mint-it {
    display: flex;
  }
  .hero-div img.logo-mobile {
    display: none;
  }
  @media only screen and (max-width: 991px) {
    padding: 0px 10px;
    .icon img {
      width: 60px;
    }
    .hero-footer {
      border-radius: 30px;
      padding: 15px;
    }
    .hero-footer h4 {
      font-size: 30px;
    }
    .borders {
      padding: 4px 4px;
    }
    .borders-left {
      padding: 8px 4px;
    }
    .borders-right {
      padding: 8px 4px;
    }
    .buttons {
      // display:flex;
    }
    .button,
    .wallet_button {
      // font-size:12px;
      // padding:12px 10px;
    }
  }
  @media only screen and (max-width: 767px) {
    .hero-div img {
      display: block;
      margin: 0 auto;
      max-width: 340px;
    }
    .hero-div img.logo-mobile {
      display: block;
      margin: 0 auto 20px;
    }
  }
`;

const HeroSection = () => {
  const [mint, setMint] = useState(1);
  const [successfulPurchase, setsuccessfulPurchase] = useState(false);
  const [accountConnected, setAccountConnected] = useState(false);
  const [hasError, setHasError] = useState("");
  const [rewardHasError, setRewardHasError] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const { account, library } = useWeb3React();

  //bullcontract.methods.aData().call({ from: account })
  //  .then((resp) => {
  // console.log('resp',resp)
  //    const string = resp[1].toString();
  //    const amount = web3.utils.fromWei(string, "ether");
  //    setShare(amount)
  //  })
  //  .catch(function () {
  //    setShare('Connect wallet to view total rewards in')
  //  })

  const mintNFT = async () => {
    setHasError("");
    setRewardHasError("");
    if (account) {
      setAccountConnected(true);
      const web3 = new Web3(library.provider);
      const bullcontract = new web3.eth.Contract(
        DAC_ABI,
        "0x8e311dB45B55DF68b7C1C8D01888a4C43986c60F"
      );
      const amount = web3.utils.toWei("0.05", "ether");
      bullcontract.methods
        .mintDegenApeClub(mint)
        .send({ from: account, value: amount * mint })
        .on("receipt", function (receipt) {
          setAccountConnected(true);
          setsuccessfulPurchase(true);
          setTransactionHash(receipt.transactionHash);
          setHasError("");
        })
        .on("error", function (error) {
          setHasError(error.message); // transaction rejected
          setsuccessfulPurchase(false);
        });
    } else {
      setAccountConnected(false);
    }
  };

  return (
    <>
      <Wrapper id="ape" className="wrapper">
        <Container fluid>
          <Row>
            <Col md={3} lg={3} xl={3} sm={12} xs={12} className="mx-auto">
              <div className="hero-div my-2 my-2">
                <img src={logo} alt="logo" className="logo-mobile" />
              </div>
            </Col>
          </Row>
          <Row>
            <div className="col">
              <div className="mb-4 row">
                <div className="mb-4 col-lg-7 col-12">
                  <h1 className="d-flex font-italic welcome-title mb-3 hero-title">
                    WELCOME TO THE DEGEN APE CLUB
                  </h1>
                  <p className="common-p mb-0">
                    Degen Ape Club is a collection of 5,555 unique BAYC inspired
                    original apes, as well as 5,555 unique BAYC inspired mutated
                    apes built on the BNB Chain.
                  </p>

                  <div className="test-footer">
                    <div className="row">
                      <div className="mb-3 col-12">
                        <h3 className="mint-title">
                          Mutation Lab is now live!
                        </h3>
                        <p className="mint-p">
                          All 5,555 Degen Apes are now available for mutating!
                        </p>
                      </div>
                    </div>
                    <Row className="middeling">
                      <Link to="/mutation-lab">
                        <div className="s_button">
                          Click here to enter the lab
                        </div>
                      </Link>
                      {/* <Col md={12} lg={12}>
                <h1 className="py-3 py-lg-3">SOLD OUT</h1>
              </Col> */}
                      {/*        <Col md={5} lg={9} className="">
                        <Row className="mint-it">
                          <Col className="px-2 pt-4">
                            <select name="dropdown" id="dropdown" onChange={handleChange}>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                            </select>
                            <button className="button" onClick={mintNFT}>MINT</button>
                          </Col>  
                        </Row>
            </Col> */}
                    </Row>
                    {hasError ? (
                      <p>
                        <br />
                        {hasError}
                      </p>
                    ) : (
                      ""
                    )}
                    {successfulPurchase ? (
                      <p>
                        <br />
                        You have successfully minted an ape!
                        <a
                          className="link-dark"
                          href={`/mycollection`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click to view your collection.
                        </a>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="my-lg-auto col-lg-4 col-12 offset-lg-1">
                  <div className="common-container">
                    <div className="row">
                      <div className="pb-2 pr-2 col-6">
                        <img
                          className="img-fluid"
                          src={previewone}
                          alt="preview one"
                          aria-label="preview one"
                        ></img>
                      </div>
                      <div className="pb-2 pl-2 col-6">
                        <img
                          className="img-fluid"
                          src={previewtwo}
                          alt="preview two"
                          aria-label="preview two"
                        ></img>
                      </div>
                    </div>
                    <div className="row">
                      <div className="pt-2 pr-2 col-6">
                        <img
                          className="img-fluid"
                          src={previewthree}
                          alt="preview three"
                          aria-label="preview three"
                        ></img>
                      </div>
                      <div className="pt-2 pl-2 col-6">
                        <img
                          className="img-fluid"
                          src={previewfour}
                          alt="preview four"
                          aria-label="preview four"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-12">
                <h3 className="fair-title">FAIR DISTRIBUTION</h3>
                <h4 className="ponzi">(BONDING CURVES ARE A FUCKING SCAM)</h4>
              </div>
              <div className="mb-2 mb-lg-0 col-lg-7 col-12">
                <p className="common-p mb-lg-0">
                  Minting an original Degen Ape costed 0.05 BNB. The price to
                  mutate an original Degen Ape costs 0.03 BNB. There was never
                  any price tiers and there never will be; Being a degen costs
                  the same for everyone.
                </p>
              </div>
            </div>
          </Row>

          <div className="test-footer">
            <h4 className="title mb-0">
              INTERACT WITH THE COMMUNITY:
              <a
                href="https://twitter.com/degenapeclub"
                target="_b"
                rel="noreferrer"
              >
                <div className="s_button">twitter</div>
              </a>
              <a
                href="https://t.me/DegenApeClubBSC"
                target="_b"
                rel="noreferrer"
              >
                <div className="s_button">telegram</div>
              </a>
              <a
                href="https://discord.gg/Tt7up5ufRX"
                target="_b"
                rel="norefferer"
              >
                <div className="s_button">discord</div>
              </a>
            </h4>
          </div>

          <div className="test-footer">

            <h4 className="title mb-0"><br /></h4>

            <h4 className="title mb-0">
              SHOP DEGEN APE CLUB MARKETPLACES: <br></br>
         {/*     <a
                href="https://nftkey.app/collections/degenapeclub/"
                target="_b"
                rel="noreferrer"
              >
                <div className="s_button">NFTKEY 🔑</div>
              </a>
              <a
                href="https://www.thebullsoc.com/marketplace/0x8e311db45b55df68b7c1c8d01888a4c43986c60f"
                target="_b"
                rel="noreferrer"
              >
                <div className="s_button">The Bull Society 🐂</div>
              </a>
              <a
                href="https://www.galler.io/en/collection/7245fcbcff6548ada47c62f9dc9098eb"
                target="_b"
                rel="norefferer"
              >
                <div className="s_button">Galler 🎨</div>
                    </a>
                    
                            <a
                href="https://opensea.io/collection/degenapeclubbnb"
                target="_b"
                rel="norefferer"
              >
                <div className="s_button">OpenSea ⛵</div>
              </a>aaaaaaaaaaaaaa*/}
              <a
                href="https://pancakeswap.finance/nfts/collections/0x8e311dB45B55DF68b7C1C8D01888a4C43986c60F"
                target="_b"
                rel="norefferer"
              >
                <div className="s_button">PancakeSwap 🥞</div>
              </a>
              <a
                href="https://rareboard.com/degenapeclub"
                target="_b"
                rel="norefferer"
              >
                <div className="s_button">Rareboard ⭐️</div>
              </a>
            </h4>

            <h4 className="title mb-0"><br /></h4>

            <h4 className="title mb-0">
              SHOP MUTANT DEGEN APE CLUB MARKETPLACES: <br></br>
         {/*}    www <a
                href="https://nftkey.app/collections/mutantdegenapeclub/"
                target="_b"
                rel="noreferrer"
              >
                <div className="s_button">NFTKEY 🔑</div>
                  </a> 
              <a
                href="https://opensea.io/collection/mutantdegenapeclub"
                target="_b"
                rel="norefferer"
              >
                <div className="s_button">OpenSea ⛵</div>
              </a> */}
              <a
                href="https://www.rareboard.com/mutantdegenapeclub"
                target="_b"
                rel="noreferrer"
              >
                <div className="s_button">Rareboard ⭐️</div>
              </a>
            </h4>
          </div>
        </Container>
      </Wrapper>
    </>
  );
};

export default HeroSection;
