import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Rodri from "../../images/rodri.png";
import Akui from "../../images/Akui.png";
import Beans from "../../images/Beans.png";
import Pickles from "../../images/Pickles.png";
import Yolo from "../../images/Yolo.png";
//padding used to be 100x100
const Wrapper = styled.div`
  padding: 0px 0px;
  color: var(--text-color);

  .t-title {
    text-transform: uppercase;
    font-size:40px ;
    font-weight:700;
    font-family: "Retro Gaming", sans-serif;
    text-align: left;
    margin-bottom: 25px !important;
    color: var(--mint);
    text-decoration: underline;
  }

  hr {
    border: 2px solid var(--mint);
  }

  .social img {
    display: inline !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px !important;
    width: 40px !important;
    padding-bottom: 20px;
  }

  .link, .link a {
    color: var(--mint) !important;
  }

  .link:hover, .link a:hover {
    color: white;
  }

  .person {
    padding-top: 5px;
    text-transform: uppercase;
    font-size: 35px;
    font-weight: 700;
    font-family: "Retro Gaming", "arco2", sans-serif !important;
    background: var(--mint);
    text-align: center;
    padding: 5px 10px 10px 10px!important;
    color: black;
  }

  .style2 {
    margin-bottom: 20px;

    img {
      width: 100%;
      display: block;
      background: var(--mint);
    }
  }
/*
  .box img{
    width: 120px;
    border-radius:0px;
  }
  .team-text{
      font-size:25px;
      margin-left:15px !important;
      font-family: "", sans-serif;
      font-weight:700;
  }
  .position{
    font-size:14px;
    font-family: "brln", sans-serif;
    font-weight:500;
  } */

}
@media only screen and (max-width: 991px) {
  // padding: 120px 10px;
}
@media only screen and (max-width: 767px) {
  // padding: 120px 50px;
}
@media only screen and (max-width: 520px) {
  // padding: 120px 10px;

  /* .box {
    width: 120px;
    border-radius:8px;
    background: var(--main-color);
  }
  .team-text{
    font-size:15px;
  }
  .position{
    font-size:11px;
  }
  .style1 {
    flex: 100%;
    padding-bottom: 20px;
  } */
  .style2 {
    flex: 100%;
  }
}
@media only screen and (max-width: 435px) {
  padding: 50px 10px;
  /* .box {
    width: 120px;
    border-radius:8px;
    background: var(--main-color);
  }
  .team-text{
    font-size:15px;
  }
  .position{
    font-size:11px;
  }
  .style1 {
    flex: 100%;
    padding-bottom: 20px;
  } */
  .style2 {
    flex: 100%;
  }
}
`;

const Team = () => {
  return (
    <Wrapper id="team" className="wrapper">
      <Container fluid>
        <h3 className="t-title">The Team</h3>
        <Row className="py-4">
          <Col xs="6" sm="3" className="style2">
            <p className="person">Akui</p>
            <img src={Akui} alt="" />
            <p className="person social">FOUNDER/DEV</p>
          </Col>
          <Col xs="6" sm="3" className="style2">
            <p className="person">YOLO</p>
            <img src={Yolo} alt="" />
            <p className="person social">COO</p>
          </Col>
          <Col xs="6" sm="3" className="style2">
            <p className="person">Rodri.</p>
            <img src={Rodri} alt="" />
            <p className="person social">Web3 Dev</p>
          </Col>
          <Col xs="6" sm="3" className="style2">
            <p className="person">Pickles.eth</p>
            <img src={Pickles} alt="" />
            <p className="person social">Artist</p>
          </Col>
        </Row>
      </Container>
      <h4 className="py-3 text-center">
        <a
          className="link"
          href="https://bscscan.com/token/0x8e311dB45B55DF68b7C1C8D01888a4C43986c60F"
          target="_blank"
          rel="noreferrer"
        >
          VERIFIED SMART CONTRACT: 0x8e311dB45B55DF68b7C1C8D01888a4C43986c60F
        </a>
      </h4>
      <h4 className="py-3 text-center">
        <a
          className="link"
          href="https://bscscan.com/address/0xa50a7a99c6937ab77E075199D23450086b2Db7bC"
          target="_blank"
          rel="noreferrer"
        >
          VERIFIED MUTATED APE CONTRACT:
          0xa50a7a99c6937ab77E075199D23450086b2Db7bC
        </a>
      </h4>
      <hr />
    </Wrapper>
  );
};

export default Team;
