import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import Web3 from "web3";
import useMutants from "../../hooks/useMutants";
import useMyDAC from "../../hooks/useMyDAC";
import bnbLogo from "../../images/bnb.png";
import clsx from "clsx";
import { Loader } from "../MyDAC/components";
import { faqs } from "./constants/faqs";
import {
  AccordionContent,
  AIButton,
  AIState,
  AItem,
  ApeCard,
  FreeMutation,
  MutantsWrapper,
  MutateButton,
  MutatedSign,
  Question,
  QuestionContainer,
  SquareSection,
} from "./components";

const renderMutationPanel = ({
  selected,
  mutantPrice,
  mutateDAC,
  onMutateSuccessful,
  setStatusMessage,
  statusMessage,
  freeMutation,
}) => {
  const unitPrice = Web3.utils.fromWei(String(mutantPrice));

  return (
    <SquareSection>
      <h2>Mutation Panel</h2>
      <div className="SquareSection-content">
        <div className="SquareSection-content_flex">
          <p className="tokens-selected">
            Tokens selected: <span>{selected.join(", ")}</span>
          </p>
          <div>
            <p className="mutate-price">
              Price: <span>{(unitPrice * selected.length).toFixed(2)}</span> BNB{" "}
              <img
                className="bnb-logo"
                src={bnbLogo}
                alt="bnb logo"
                width={20}
              />
            </p>
            <MutateButton
              type="button"
              // className="mutate-button"
              disabled={!selected.length}
              onClick={() =>
                mutateDAC(selected, onMutateSuccessful, setStatusMessage)
              }
            >
              Mutate selected
            </MutateButton>
            <p>{statusMessage}</p>
          </div>
        </div>
      </div>
    </SquareSection>
  );
};

const renderFaqSection = (freeMutation) => (
  <SquareSection>
    <h2>Mutation F.A.Q.</h2>
    <div className="SquareSection-content">
      <div className="SquareSection-content-faq_flex">
        <Accordion allowZeroExpanded={true}>
          {faqs.map((faq) => {
            return (
              <AItem key={faq.q}>
                <AccordionItemHeading>
                  <AIButton>
                    <QuestionContainer>
                      <Question>{faq.q}</Question>
                      <AIState>
                        <AccordionItemState>
                          {(state) => {
                            return state.expanded ? "x" : "+";
                          }}
                        </AccordionItemState>
                      </AIState>
                    </QuestionContainer>
                  </AIButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <AccordionContent>{faq.a}</AccordionContent>
                </AccordionItemPanel>
              </AItem>
            );
          })}
        </Accordion>
        <FreeMutation>
          You have {freeMutation ? "1" : "0"} Free Mutations.
        </FreeMutation>
      </div>
    </div>
  </SquareSection>
);

const Mutants = () => {
  const { account } = useWeb3React();
  const [selected, setSelected] = useState([]);
  const { DACPurchased, getNFTCollection, tokenIds, isLoading } = useMyDAC();
  const {
    mutantPrice,
    canBeMutated,
    mutationStatus,
    mutateDAC,
    freeMutation,
    isLoadingMutants,
  } = useMutants();
  const [statusMessage, setStatusMessage] = useState("");

  useEffect(() => {
    if (tokenIds) {
      canBeMutated(tokenIds);
    }
  }, [tokenIds, canBeMutated]);

  useEffect(() => {
    getNFTCollection();
  }, [account, getNFTCollection]);

  const onMutateSuccessful = () => {
    setSelected([]);
    getNFTCollection();
    setStatusMessage("Mutation successful!");
  };

  return (
    <MutantsWrapper>
      <div className="Mutants-LeftPanel">
        <div className="Mutants-panel">
          {renderMutationPanel({
            selected,
            mutantPrice,
            mutateDAC,
            onMutateSuccessful,
            setStatusMessage,
            statusMessage,
          })}
        </div>
        <div className="Mutants-faq">{renderFaqSection(freeMutation)}</div>
      </div>
      <div className="Mutants-grid">
        {!account ? (
          <p>Connect your wallet, please.</p>
        ) : isLoading || isLoadingMutants ? (
          <Loader>Loading...</Loader>
        ) : DACPurchased.length === 0 ? (
          <p>No DACs found on this wallet.</p>
        ) : (
          DACPurchased.map((ape) => {
            const canBeMutated = mutationStatus.CanBeMutated?.includes(
              +ape.tokenId
            );

            return (
              <ApeCard
                key={ape.tokenId}
                onClick={() => {
                  if (canBeMutated) {
                    if (selected.includes(ape.tokenId)) {
                      setSelected(
                        selected.filter((apeId) => apeId !== ape.tokenId)
                      );
                    } else {
                      setSelected([...selected, ape.tokenId]);
                    }
                  }
                }}
                className={clsx({
                  selected: selected.includes(ape.tokenId),
                })}
              >
                {!canBeMutated && <MutatedSign>Mutated</MutatedSign>}
                <div
                  className={clsx({
                    disabled: !canBeMutated,
                  })}
                >
                  <img src={ape.image} alt={ape.name} />
                  <p className="ape-name">{ape.name}</p>
                </div>
              </ApeCard>
            );
          })
        )}
      </div>
    </MutantsWrapper>
  );
};

export default Mutants;
