import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
import WalletButton from "../WalletConnect/WalletConnect";
import useMyDAC from "../../hooks/useMyDAC";
import TransferModal from "../TransferModal/TransferModal";
import { Loader, MyDACWrapper, SpacedCol } from "./components";

const MyDAC = () => {
  const { account } = useWeb3React();
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [tokenId, setTokenId] = useState(0);
  const [mintedMessage, setMintedMessage] = useState(
    "Connect to your wallet to see your collection of apes."
  );
  const [selectValue, setSelectValue] = useState(false);
  const isMutant = selectValue === "mutant";
  const { DACPurchased, getNFTCollection, error, isLoading } =
    useMyDAC(isMutant);

  const onSuccessfulTransfer = () => {
    getNFTCollection();
  };

  useEffect(() => {
    getNFTCollection();
  }, [account, getNFTCollection, selectValue]);

  const onClickTransferApe = (id) => {
    setShowTransferModal(true);
    setTokenId(id);
  };

  useEffect(() => {
    if (DACPurchased && Array.isArray(DACPurchased)) {
      if (DACPurchased.length === 0) {
        setMintedMessage("Go purchase an ape to view it here!");
      } else {
        setMintedMessage(
          `You currently own ${DACPurchased.length} ${
            DACPurchased.length === 1 ? "Ape" : "Apes"
          }.`
        );
      }
    }
  }, [DACPurchased]);

  if (!account) {
    return (
      <MyDACWrapper id="howitworks">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={12} className="h-section">
              <div className="mobile-only">
                <WalletButton />
              </div>
              <p id="sub-title">
                It seems you aren't connected to the correct blockchain, or you
                aren't connected at all. <br />
                Connect your wallet to continue.
              </p>
            </Col>
          </Row>
        </Container>
      </MyDACWrapper>
    );
  }

  if (error) {
    return (
      <MyDACWrapper id="howitworks">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={12} className="h-section">
              <p id="sub-title">{error}</p>
            </Col>
          </Row>
        </Container>
      </MyDACWrapper>
    );
  }

  return (
    <>
      <MyDACWrapper id="howitworks">
        <Container fluid>
          <Row className="align-items-center">
            {isLoading ? (
              <Col md={12} className="h-section">
                <Loader>Loading...</Loader>
              </Col>
            ) : (
              <>
                <Col md={12} className="h-section">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h3 className="h-title text-left">My Apes</h3>
                    <select
                      onChange={(e) => {
                        setSelectValue(e.target.value);
                      }}
                      value={selectValue}
                    >
                      <option value="normal">Degen Ape club</option>
                      <option value="mutant">Mutant Degen Ape Club</option>
                    </select>
                  </div>
                  <p className="sub-title2">{mintedMessage}</p>
                </Col>
                <Col md={12}>
                  <div className="h-image">
                    <Row>
                      {DACPurchased.map((currentDAC) => {
                        return (
                          <SpacedCol
                            xs={6}
                            md={4}
                            lg={3}
                            className="apeimage"
                            key={currentDAC.tokenId}
                          >
                            <p className="ape-title">{currentDAC.name}</p>
                            <img
                              id={currentDAC.name}
                              src={currentDAC.image}
                              alt={currentDAC.name}
                            />
                            {Object.keys(currentDAC)
                              .filter(
                                (key) =>
                                  key !== "image" &&
                                  key !== "tokenId" &&
                                  key !== "name"
                              )
                              .map((key) => (
                                <p className="ape-descriptor" key={key}>
                                  {key}: {currentDAC[key]}
                                </p>
                              ))}
                            <button
                              type="button"
                              className="transferButton"
                              onClick={() =>
                                onClickTransferApe(currentDAC.tokenId)
                              }
                            >
                              Send Ape
                            </button>
                          </SpacedCol>
                        );
                      })}
                    </Row>
                  </div>
                </Col>
              </>
            )}
          </Row>
          <TransferModal
            show={showTransferModal}
            setShowTransferModal={() => setShowTransferModal(false)}
            tokenId={tokenId}
            onSuccessfulTransfer={onSuccessfulTransfer}
            isMutant={isMutant}
          />
        </Container>
      </MyDACWrapper>
    </>
  );
};

export default MyDAC;
