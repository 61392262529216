import Web3 from "web3";
import { DAC_ABI } from "../ABI/DAC";
import { DAC_CONTRACT_ADDRESS, MUTANTS_CONTRACT_ADDRESS } from "../config";

const isValidAddress = (adr) => {
  try {
    const web3 = new Web3();
    web3.utils.toChecksumAddress(adr);
    return true;
  } catch (e) {
    return false;
  }
};

const transfer = async ({
  transferAddress,
  tokenId,
  account,
  library,
  isMutant,
}) => {
  if (isValidAddress(transferAddress)) {
    const web3 = new Web3(library.provider);
    const DACCONTRACT = new web3.eth.Contract(
      DAC_ABI,
      isMutant ? MUTANTS_CONTRACT_ADDRESS : DAC_CONTRACT_ADDRESS
    );

    return new Promise((resolve, reject) => {
      try {
        DACCONTRACT.methods
          .safeTransferFrom(account, transferAddress, tokenId)
          .send({ from: account, to: transferAddress })
          .then(() => {
            resolve();
          })
          .catch((e) => reject(e));
      } catch (e) {
        reject(new Error("An error ocurred. Check the address and try again."));
      }
    });
  } else {
    throw new Error("Please, insert a valid address.");
  }
};

export { isValidAddress, transfer };
