import HeroSection from "../Components/HeroSection/HeroSection";
import Team from "../Components/Team/Team";
import Footer from "../Components/Footer/Footer";

const Home = () => {
  return (
    <>
      <HeroSection />
      <Team /> 
       <Footer />
    </>
  );
};

export default Home;
