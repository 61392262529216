import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import { GiHamburgerMenu } from "react-icons/gi";
import Sidebar from "./Sidebar";
import WalletButton from "../WalletConnect/WalletConnect";
import logo from "../../images/logo.png";
import { NavbarWrapper } from "./components";

const Navbar = () => {
  const [sidebar, setSidebar] = useState(false);

  const toggleSidebar = () => {
    setSidebar((prev) => !prev);
  };

  return (
    <NavbarWrapper>
      <div className="py-3">
        <Row>
          <Col>
            <div className="menu-container">
              <div className="logo-connect my-2">
                <img src={logo} alt="logo" width="380px" className="mb-3" />
              </div>
              <Link to="/">Homepage</Link>
              <Link to="/#team">Team</Link>
              <Link to="/mycollection">My Apes</Link>
              <Link to="/mutation-lab">Mutation Lab</Link>
              <WalletButton />
            </div>
            <GiHamburgerMenu
              className="hamburger"
              size="30"
              color="var(--text-color)"
              onClick={toggleSidebar}
            />
          </Col>
        </Row>
        {sidebar && <Sidebar showSidebar={toggleSidebar} toggleSidebar={toggleSidebar} />}
      </div>
    </NavbarWrapper>
  );
};

export default Navbar;
