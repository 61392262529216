import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { transfer } from "../../utils/web3";
import { ErrorText, TransferModalContainer } from "./components";

const TransferModal = ({
  show,
  setShowTransferModal,
  tokenId,
  onSuccessfulTransfer,
  isMutant
}) => {
  const { account, library } = useWeb3React();
  const [transferAddress, setTransferAddress] = useState("");
  const [error, setError] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const handleTransfer = async () => {
    setStatusMessage("Transferring...");

    transfer({
      transferAddress,
      tokenId,
      account,
      library,
      isMutant
    })
      .then(() => {
        setStatusMessage("Transfer Successful.");
        onSuccessfulTransfer();
      })
      .catch((e) => {
        setStatusMessage("");
        setError(e?.message);
      });
  };

  return (
    <TransferModalContainer
      className="transferModal"
      show={show}
      onHide={() => {
        setShowTransferModal(false);
        setError("");
        setStatusMessage("");
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontFamily: "Upheaval" }}>
          Input Address Below
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          type="text"
          value={transferAddress}
          style={{
            width: "100%",
            fontFamily: "Segoe UI",
            color: "#ffffff",
            background: "#000",
          }}
          onChange={(e) => setTransferAddress(e.target.value)}
        />
        <ErrorText>{error}</ErrorText>
      </Modal.Body>
      <Modal.Footer>
        {statusMessage && <p>{statusMessage}</p>}
        <Button
          variant="secondary"
          style={{
            fontFamily: "upheaval",
            color: "white",
            background: "#000",
            border: "1px solid var(--mint)",
            borderRadius: "0px",
          }}
          onClick={handleTransfer}
        >
          Send Ape
        </Button>
      </Modal.Footer>
    </TransferModalContainer>
  );
};

export default TransferModal;
