import styled from "styled-components";
import { AccordionItem, AccordionItemButton } from "react-accessible-accordion";

export const MutantsWrapper = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-column-gap: 35px;
  grid-template-areas:
    "Mutants-LeftPanel Mutants-grid"
    "Mutants-LeftPanel Mutants-grid";
  max-width: 1190px;
  justify-items: center;
  margin: 0 auto;

  @media (max-width: 1190px) {
    max-width: 910px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "Mutants-grid"
      "Mutants-LeftPanel";
    margin: 15px auto;
  }

  @media (max-width: 850px) {
    padding: 0px 15px;
    margin: 15px auto;
  }

  .Mutants-LeftPanel {
    display: grid;
    height: 588px;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "Mutants-panel"
      "Mutants-faq";
    grid-row-gap: 35px;
    width: 100%;
  }

  .Mutants-panel {
    grid-area: Mutants-panel;
  }

  .Mutants-faq {
    grid-area: Mutants-faq;
  }

  .Mutants-grid {
    color: #fff;
    grid-area: Mutants-grid;
    display: grid;
    grid-template-columns: repeat(3, 245px);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 35px;
    grid-row-gap: 35px;
    max-width: 1000px;
    max-height: 588px;
    overflow: auto;
    margin-bottom: 35px;

    @media (max-width: 850px) {
      grid-template-columns: repeat(2, 177px);
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      max-height: 500px;
    }
  }
`;

export const SquareSection = styled.div`
  font-family: "Retro Gaming", sans-serif;
  text-align: center;
  color: white;
  background-color: #1c1b1b;
  width: 100%;
  height: 100%;

  h2 {
    background: var(--mint);
    color: var(--black);
    font-family: "Retro Gaming", sans-serif !important;
    font-size: 25px;
    letter-spacing: -1px;
    padding: 10px !important;
  }

  .SquareSection-content {
    padding: 15px 10px;
    height: calc(100% - 50px);

    .SquareSection-content_flex {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .SquareSection-content-faq_flex {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .Accordion {
        text-align: left;
        width: 100%;
      }

      .accordion {
        text-align: left;
        width: 100%;
      }
    }
  }

  .tokens-selected {
    margin-bottom: 20px !important;
    overflow: auto;
  }

  .mutate-price {
    font-size: 18px;

    .bnb-logo {
      margin-top: -4px;
    }
  }
`;

export const MutateButton = styled.button`
  font-size: 20px;
  text-transform: capitalize;
  padding: 10px 30px;
  outline: none;
  color: var(--black);
  background-color: var(--mint);
  border: none;
  text-overflow: ellipsis;

  &:disabled {
    opacity: 0.5;
  }
`;

export const AItem = styled(AccordionItem)`
  padding: 0px 10px;
  cursor: pointer;
  color: white;
  margin-bottom: 15px;
`;

export const Question = styled.p`
  font-family: "Retro Gaming", "Upheaval", sans-serif !important;
  font-size: 17px;
  font-weight: 700;
`;

export const QuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AIState = styled.div`
  font-family: "Retro Gaming", "Upheaval", sans-serif !important;
  font-size: 20px;
  font-weight: 700;
`;

export const AIButton = styled(AccordionItemButton)`
  font-size: 2.5rem;
  font-family: "Cheapsman-Free-Regular";
`;

export const AccordionContent = styled.p`
  margin-top: 15px !important;

  @media (max-width: 1023px) {
  }
`;

export const ApeCard = styled.div`
  position: relative;
  padding: 12px 12px 10px 12px;
  color: var(--mint);
  max-width: 100%;
  background-color: #1c1b1b;
  text-align: center;
  cursor: pointer;
  border: 2px solid #1c1b1b;

  &.selected {
    border: 2px solid var(--mint);
  }

  & > *.disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }

  img {
    max-width: 220px;

    @media (max-width: 850px) {
      max-width: 153px;
    }
  }

  .ape-name {
    margin-top: 8px !important;
    font-size: 15px;
  }
`;

export const MutatedSign = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  font-size: 35px;
  font-family: "Retro Gaming", sans-serif;
`;

export const FreeMutation = styled.p`
  font-size: 16px;
  text-transform: capitalize;
  padding: 5px 20px !important;
  color: var(--black);
  background-color: var(--mint);
  text-overflow: ellipsis;
`;
