import { useCallback, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { useWalletModal } from "@pantherswap-libs/uikit";
import { injected, bsc, walletconnect } from "../../connectors";
import Web3 from "web3";

const WalletConnect = () => {
  const { account, activate, deactivate } = useWeb3React();

  const handleLogin = useCallback(
    (connectorId) => {
      if (connectorId === "walletconnect") {
        return activate(walletconnect);
      } else if (connectorId === "bsc") {
        return activate(bsc);
      }
      return activate(injected);
    },
    [activate]
  );

  const signIn = useCallback(() => {
    const web3 = new Web3(Web3.givenProvider);

    window.ethereum?.enable().then(function (accounts) {
      web3.eth.getChainId().then((chainId) => {
        if (chainId !== 56) {
          alert(
            "You are on the wrong network. Change network to Binance Smart Chain."
          );
        } else {
          let wallet = accounts[0];
          handleLogin(wallet);
        }
      });
    });
  }, [handleLogin]);

  useEffect(() => {
    signIn();
  }, []); // eslint-disable-line

  const { onPresentConnectModal } = useWalletModal(
    handleLogin,
    deactivate,
    account
  );

  const { onPresentAccountModal } = useWalletModal(
    handleLogin,
    deactivate,
    account
  );

  return !account ? (
    <button
      type="button"
      className="wallet_button not-connected"
      onClick={onPresentConnectModal}
    >
      Connect Wallet
    </button>
  ) : (
    <button
      type="button"
      className="wallet_button connected"
      onClick={onPresentAccountModal}
    >
      Connected Wallet
    </button>
  );
};

export default WalletConnect;
