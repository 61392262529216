import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: var(--mint);
  color: #fff;
  padding: 20px 0;
  font-family: "brln", sans-serif;
  width: 100%;
  .social-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .social-container img {
    width: 40px;
  }
  .soocial-container p {
    font-weight: 700;
  }
  .footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .footer-links img {
    width: 60px;
  }
  .footer-links a {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }
  .test-footer {
    background: var(--mint);
    color: #000;
    text-align: center;
    padding: 20px 0 20px;
    margin: 0 auto;
    margin-top: 50px;
  }
  .title {
  font-size: 30px;
  font-weight: 700;
  }
  .affiliated {
    font-size: 30px;
    font-family: "Upheaval";
    color: black;
    text-align: center;
  }
  a {
    color: black;
  }
`;

const Footer = () => {
  return (
     <Wrapper id="footer" className="wrapper">
      <div className="affiliated">We are not affiliated with the <a href="https://boredapeyachtclub.com/#/home" target="_b" rel="norefferer">Bored Ape Yacht Club</a> OR <a href="https://www.yugalabs.io/" target="_b" rel="norefferer">YUGA LABS.</a></div>
     </Wrapper> 
  );
};

export default Footer;