import { Col } from "react-bootstrap";
import styled from "styled-components";

export const MyDACWrapper = styled.div`
  padding: 40px 0;

  color: var(--text-color);
  .h-section {
    padding-bottom: 40px;
    font-family: "Retro Gaming", "Upheaval" !important;
  }

  .h-title {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    text-shadow: 3px 3px 3px var(--mint);
  }

  .sub-title {
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Retro Gaming", "Upheaval", sans-serif !important;
  }

  .sub-title2 {
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Retro Gaming", "Upheaval", sans-serif !important;
    text-align: left !important;
  }

  .h-image img {
    width: 100%;
  }

  p {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    font-family: "Retro Gaming", sans-serif !important;
  }

  .ape-title {
    font-family: "Retro Gaming", "Upheaval", sans-serif !important;
    color: var(--mint);
    height: auto;
    padding-bottom: 1rem !important;
    text-align: center;
  }

  .ape-descriptor {
    padding: 4px 2px !important;
    text-align: center;
    font-size: 17px;
  }

  .wallet_button {
    text-transform: uppercase;
    display: inline-block;
    padding: 15px 20px;
    font-family: "brln", sans-serif;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: var(--mint);
    border: none;
    border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 12px;
  }

  .wallet_button:hover {
    background-color: #6891ed;
  }

  .transferButton {
    display: block;
    padding: 5px 10px;
    font-size: 24px;
    font-family: "Upheaval", sans-serif;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: var(--mint);
    background-color: var(--black);
    border: 2px solid var(--mint);
    width: auto;
    margin: 4% auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .transferButton:hover {
    background-color: var(--mint);
    color: var(--black);
  }

  .mobile-only {
    display: none;
  }

  @media only screen and (max-width: 991px) {
    padding: 0px 10px;
    .wallet_button {
      margin-left: 0;
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding-top: 80px;
    .mobile-only {
      display: block;
    }
  }

  @media only screen and (max-width: 435px) {
    padding: 0 10px;
    padding-top: 120px;
    .wallet_button {
      width: 100%;
    }
  }
`;

export const SpacedCol = styled(Col)`
  margin-bottom: 10px;
  padding: 5px;
`;

export const Loader = styled.div`
  border-radius: 50%;
  width: 10em;
  height: 10em;
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  overflow: hidden;

  &::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
