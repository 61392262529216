import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const TransferModalContainer = styled(Modal)`
  .modal-content {
    background-color: #000;
    border: 2px solid var(--mint);
    margin-top: 200px;
    color: rgb(180, 248, 200);
  }
  .modal-content input:focus {
    outline: 0px solid var(--mint);
    border: 2px solid var(--mint);
  }
  .modal-content input {
    border: 2px solid white;
  }
`;

export const ErrorText = styled.p`
  color: red;
  font-size: 12px;
`;