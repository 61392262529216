import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import Web3 from "web3";
import { MUTANTS_ABI } from "../ABI/MUTANTS";
import { MUTANTS_CONTRACT_ADDRESS } from "../config";

const useMutants = () => {
  const { account, library } = useWeb3React();
  const [mutantPrice, setMutantPrice] = useState(0);
  const [mutationStatus, setMutationStatus] = useState({});
  const [freeMutation, setFreeMutation] = useState(false);
  const [isLoadingMutants, setIsLoading] = useState(true);

  const getMutantContract = async () => {
    const web3 = new Web3(Web3.givenProvider || "http://localhost:3000");
    return new web3.eth.Contract(MUTANTS_ABI, MUTANTS_CONTRACT_ADDRESS);
  };

  const getPrice = useCallback(async () => {
    const price = await getMutantContract().then(async (contract) =>
      contract.methods.price().call()
    );

    setMutantPrice(price);
  }, [library]);

  useEffect(() => {
    checkFreeMutation();
  }, [account]);

  const canBeMutated = useCallback(
    async (tokenIds) => {
      setIsLoading(true);
      if (account && tokenIds.length > 0) {
        const web3 = new Web3(library.provider);
        const MUTANTS_CONTRACT = new web3.eth.Contract(
          MUTANTS_ABI,
          MUTANTS_CONTRACT_ADDRESS
        );

        const dacsCanBeMutated = await MUTANTS_CONTRACT.methods
          .dacsCanMutate(tokenIds)
          .call();

        const mutationStatusObject = {
          CanBeMutated: [],
          CannotBeMutated: [],
        };

        dacsCanBeMutated.forEach((canBeMutated, index) => {
          if (canBeMutated) {
            mutationStatusObject.CanBeMutated.push(tokenIds[index]);
          } else {
            mutationStatusObject.CannotBeMutated.push(tokenIds[index]);
          }
        });

        setMutationStatus(mutationStatusObject);
      }
      setIsLoading(false);
    },
    [library, account]
  );

  const mutateDAC = useCallback(
    async (tokenIds, onMutateSuccessful, setStatusMessage) => {
      if (account && tokenIds.length > 0) {
        const web3 = new Web3(library.provider);
        const MUTANTS_CONTRACT = new web3.eth.Contract(
          MUTANTS_ABI,
          MUTANTS_CONTRACT_ADDRESS
        );

        let mutationPrice = mutantPrice * tokenIds.length;

        if (freeMutation) {
          mutationPrice -= mutantPrice;
        }

        try {
          setStatusMessage("Mutating...");

          MUTANTS_CONTRACT.methods
            .mutate(tokenIds)
            .send({ from: account, value: mutationPrice })
            .then(() => {
              onMutateSuccessful();
            })
            .catch((error) => {
              const regex = /Mutation not started yet/;
              if (regex.test(error.message)) {
                setStatusMessage("Mutation not started yet.");
              }
            });
        } catch (e) {
          console.log(e);
          setStatusMessage("Something went wrong. Please try again.");
        }
      }
    },
    [library, account, mutantPrice, freeMutation]
  );

  const checkFreeMutation = useCallback(async () => {
    if (account) {
      const web3 = new Web3(library.provider);
      const MUTANTS_CONTRACT = new web3.eth.Contract(
        MUTANTS_ABI,
        MUTANTS_CONTRACT_ADDRESS
      );

      const freeMutation = await MUTANTS_CONTRACT.methods
        .canMutateForFree(account)
        .call();

      setFreeMutation(freeMutation);
    }
  }, [library, account]);

  useEffect(() => {
    if (account) {
      getPrice();
      checkFreeMutation();
    }
  }, [account, library, getPrice, checkFreeMutation]);

  return {
    mutantPrice,
    canBeMutated,
    mutationStatus,
    mutateDAC,
    checkFreeMutation,
    freeMutation,
    isLoadingMutants,
  };
};

export default useMutants;
