import { IoMdClose } from "react-icons/io";
import { HashLink as Link } from "react-router-hash-link";
import WalletButton from "../WalletConnect/WalletConnect";
import { SidebarWrapper } from "./components";

const Sidebar = ({ showSidebar, toggleSidebar }) => (
  <SidebarWrapper>
    <div className="sidebar-container">
      <div className="close">
        <IoMdClose color="#fff" size={30} onClick={showSidebar} />
      </div>
      <div className="sidebar">
        <Link to="/" onClick={toggleSidebar}>
          Homepage
        </Link>
        <Link to="/#team" onClick={toggleSidebar}>
          Team
        </Link>
        <Link to="/mycollection" onClick={toggleSidebar}>
          My Apes
        </Link>
        <Link to="/mutation-lab" onClick={toggleSidebar}>
          Mutation Lab
        </Link>
        <WalletButton />
      </div>
    </div>
  </SidebarWrapper>
);

export default Sidebar;
