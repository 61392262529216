import "bootstrap/dist/css/bootstrap.min.css";
import { Switch, Route } from "react-router-dom";
import Home from "./Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import MyDAC from "./Components/MyDAC/MyDAC";
import Mutants from "./Components/Mutants/Mutants";

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route path="/mycollection" component={MyDAC} />
        <Route path="/mutation-lab" component={Mutants} />
        <Route path="/" component={Home} />
      </Switch>
    </>
  );
}

export default App;
