export const faqs = [
  {
    q: "Will I lose my ape?",
    a: "No, you will not lose your ape. After mutating you will keep your original ape and be given a mutated version of your ape.",
  },
  {
    q: "What is the price?",
    a: "The price to mutate each of your apes will be 0.03 BNB (BEP-20).",
  },
  {
    q: "Does my rank change?",
    a: "No, your ape rank does not change. All apes are a mutated version of the original apes and all ranks will be the same.",
  },
];
