import { DefaultTheme } from "styled-components";
import base from "./base";
import { darkColors } from "./colors";
import { darkButton } from "./components";

const darkTheme: DefaultTheme = {
  ...base,
  isDark: true,
  alert: { background: darkColors.card },
  button: darkButton,
  colors: darkColors,
  card: {
    background: darkColors.card,
    boxShadow:
      "0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05)",
    boxShadowActive: base.shadows.active,
    boxShadowSuccess: base.shadows.success,
    boxShadowWarning: base.shadows.warning,
    cardHeaderBackground:
      "linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)",
  },
  toggle: { handleBackground: darkColors.card },
  nav: { background: darkColors.card, hover: "#473d5d" },
  modal: { background: darkColors.card },
  radio: { handleBackground: darkColors.card },
};

export default darkTheme;
