import styled from "styled-components";

export const NavbarWrapper = styled.div`
  width: 100%;
  left: 0;
  top: 0;

  .logo-connect {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 310px;
  }

  .logo-connect img {
    width: 250px;
    height: auto;
  }

  .navbars {
    padding: 0 80px;
  }

  .logo {
    color: var(--text-color);
    font-size: 65px;
  }

  a {
    text-decoration: none;
    text-transform: uppercase;
  }

  .menu-container {
    display: -webkit-inline-box;
    justify-content: space-between;
    align-items: center;
    -webkit-box-pack: center;
    box-pack: center;
  }

  .menu-container a,
  .menu-container p {
    color: var(--text-color);
    text-decoration: none;
    font-size: 22px;
    font-family: "Retro Gaming", sans-serif;
    padding: 10px 14px;
  }

  .menu-container a:hover,
  .menu-container p:hover {
    text-shadow: 1px 3px 3px var(--mint);
  }

  .hamburger {
    display: none;
    cursor: pointer;
  }

  .wallet_button {
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 15px;
    font-family: "Retro Gaming", sans-serif;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: black;
    background-color: var(--mint);
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 20px;
  }

  .wallet_button:hover {
    background-color: var(--mint);
  }

  @media only screen and (max-width: 991px) {
    .wallet_button {
      margin-left: 0;
    }

    .navbars {
      padding: 0 50px;
    }

    .logo {
      font-size: 50px;
    }

    .menu-container a,
    .menu-container p {
      font-size: 16px;
      padding: 10px 10px;
      font-style: italic;
    }
  }

  @media only screen and (max-width: 953px) {
    .menu-container a,
    .menu-container p {
      font-size: 15px;
      padding: 10px 5px;
    }
  }

  @media only screen and (max-width: 767px) {
    .navbars {
      padding: 0 30px;
    }

    .menu-container {
      display: none;
    }

    .hamburger {
      display: block;
      margin-left: 20px;
    }
  }
`;

export const SidebarWrapper = styled.div`
  padding-bottom: 40px;
  height: 100vh;
  .sidebar-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--black);
    padding-bottom: 35px;
  }
  .sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .sidebar a {
    text-decoration: none;
    color: white;
    font-family: "Retro Gaming", "Upheaval", sans-serif;
    font-size: 18px;
    margin: 20px 0;
    padding: 10px 20px;
  }
  .close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 30px 30px 10px 0px;
    cursor: pointer;
  }
  .wallet_connect {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }
`;
